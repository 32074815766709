#boxContainer {
    display: flex;
    height: 100vh;
}

#iconMenuButton {
    color: inherit; 
    margin-right: 16px;
}

#boxMainContent {
    height: 100%;
    display: flex;
    flex-direction: column;
    padding-left: 16px;
    padding-right: 16px;
}

#drawerPermanent, #drawerTemporary {
    width: 240px;
    flex-direction: column;
}

#btnUser {
    color: inherit;
}