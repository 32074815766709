#loginContainer {
    height: 100vh;
    width: 100vw;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

#loginBloc {
    margin: 0 auto 0 auto;
    padding: 40px;
    display: flex;
    flex-direction: column;
    gap: 10px;
}

#loginDivider {
    margin-top: 20px !important; 
    margin-bottom: 20px;
}