#title {
    display: flex;
    color: #fff;
    margin-bottom: 20px;
}

#title h1 {
    font-weight: bold;
}

#iconTitle {
    margin: auto;
}

#smallSubTitle {
    font-weight: normal !important;
}